import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_AUTH_USER,
  SIGNOUT_AUTH_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FIND_USER,
  FIND_USER_SUCCESS,
  ON_PING,
  ON_PING_SUCCESS,
  ON_PING_TRAINEE,
  CLEAR_USER_DATA,
  SIGNIN_MICROSOFT_USER,
  SIGNIN_MICROSOFT_USER_SUCCESS,
  FORGOT_PASSWORD_USER,
  FORGOT_PASSWORD_USER_SUCCESS,
  RESET_PASSWORD_USER,
  RESET_PASSWORD_USER_SUCCESS,
  USER_REDIRECT,
  USER_REDIRECT_SUCCESS,
  SESSION_AUTH,
  SIGNIN_WITH_OTP
} from "constants/ActionTypes";
//
export const userRedirect = info => {
  console.log({
    info
  });
  return {
    type: USER_REDIRECT,
    payload: { token: info.token }
  };
};
export const userRedirectSuccess = info => {
  console.log({
    info
  });
  return {
    type: USER_REDIRECT_SUCCESS,
    payload: info
  };
};
export const userResetPassword = user => {
  console.log({ user });
  return {
    type: RESET_PASSWORD_USER,
    payload: user
  };
};
export const userResetPasswordSuccess = info => {
  console.log({ info });
  return {
    type: RESET_PASSWORD_USER_SUCCESS,
    payload: info
  };
};
export const userForgotPassword = user => {
  return {
    type: FORGOT_PASSWORD_USER,
    payload: user
  };
};
export const userForgotPasswordSuccess = info => {
  console.log({ info });
  return {
    type: FORGOT_PASSWORD_USER_SUCCESS,
    payload: info
  };
};

export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};

export const fetchUser = user => {
  return {
    type: FIND_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignOutAuth = (sessionAuth) => {
  return {
    type: SIGNOUT_AUTH_USER,
    payload: sessionAuth
  };
};
export const onPing = () => {
  return {
    type: ON_PING
  };
};
export const onTraineePing = () => {
  return {
    type: ON_PING_TRAINEE
  };
};
export const onPingSuccess = userInfo => {
  return {
    type: ON_PING_SUCCESS,
    payload: userInfo
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser, nextLink) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: {authUser, nextLink}
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};
export const userSignOutAuthSuccess = () => {
  return {
    type: SIGNOUT_AUTH_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  console.log("------------showAuthMessage-----------------");
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const findUserSuccess = userData => {
  return {
    type: FIND_USER_SUCCESS,
    payload: userData
  };
};
// Microsoft Login Action Start
export const userMicrosoftSignIn = (microsoftProvider, organisationData) => {
  return {
    type: SIGNIN_MICROSOFT_USER,
    payload: microsoftProvider,
    organisationData: organisationData
  };
};

export const userMicrosoftSignInSuccess = session => {
  return {
    type: SIGNIN_MICROSOFT_USER_SUCCESS,
    payload: session
  };
};

//// Microsoft Login Action End

export const userGoogleSignIn = value => {
  return {
    type: SIGNIN_GOOGLE_USER,
    payload: value
  };
};

export const userOtpSignIn = value => {
  console.log(value,'userOtpSignIn')
  return{
    type: SIGNIN_WITH_OTP,
    payload: value
  }
}

export const userGoogleSignInSuccess = authUser => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = authUser => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = authUser => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = authUser => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
}; 
export const hideAuthMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const clearUserData = payload => {
  console.log("clearUserData",payload)
  return {
    type: CLEAR_USER_DATA,
    payload: payload
  };
};

export const onSetSessionAuth = sessionAuth => {
  return {
    type: SESSION_AUTH,
    payload: sessionAuth
  };
}; 

