import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget";
import CircularProgress from "components/CircularProgress";
import { Row, Col, Card, List, Progress, Divider } from "antd";
import Model from "./model";
import TopNavbar from "../components/TopNavbar";
// import styles from "./index.less";
import store from "store";
import { api, apiUrl } from "util/config";
var moment = require("moment");

class ResultView extends PureComponent {
  state = {
    data: {},
    loading: true,
  };
  componentWillMount() {
    const { match } = this.props;
    const trainingId = match.params.trainingId;
    Model.getTrainingDetails({ trainingId: trainingId }).then((response) => {
      this.setState({ data: response.data, loading: false, trainingId, assessment: response.assessmentTraining, organisation: response.organisations });
    });
  }
  componentDidMount(){
    const user = store.get("user")
    this.setState({user})
  }
  formateDateTime(date) {
    return moment(date).format("DD MMM YYYY,  hh:mm A");
  }
  calculatePercent = (max, score) => {
    return ((score / max) * 100).toFixed(0);
  };

  shareCertificate = (type) => {
    // console.log(e.target.getAttribute("data"));
    // const type = e.target.getAttribute("data");
    var self = this;
    console.log("download");
    const { dispatch, result, match } = this.props;
    // var { data } = result;
    var { loading, data ,user} = this.state;
    const training_id = match.params.trainingId; 
    var info = { 
      traineeId: data.trainee,
      name: user.userName,
      email: user.userEmail,}
    Model.shareTrainings(training_id,info).then((response) => {
      // this.setState({ data: response.data, loading: false, trainingId });
      
      self.share(response.data, data.trainee, training_id, type);
    });
    // dispatch({
    //   type: "result/share",
    //   payload: {
    //     campaignId: training_id,
    //     traineeId: data.trainee,
    //     name: user.userName,
    //     email: user.userEmail,
    //     callback: function(url) {
    //       self.share(url, data.trainee, training_id, type);
    //     },
    //   },
    // });
  };
  share = (url, traineeId, campaignId, type) => {
    // console.log({url})
    // url = `${process.env.API_URL}/certificate/${traineeId}/${campaignId}`;
    // url =
    //   'https://staging-portal.ohphish.com/assets/images/login_icon_image.png'

    if (type == "linkedIn") {
      var link = "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(url);

      window.open(link, "", "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
    } else {
      var link = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
      window.open(link, "", "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0");
    }
  };
  downloadCertificate = (e) => {
    const { dispatch, result, match } = this.props;
    // var { data } = result;
    var { loading, data ,user} = this.state;
    const training_id = match.params.trainingId;
    // $(e.target).addClass("loading");
    var request = new XMLHttpRequest();
    request.open(
      "GET",

      apiUrl+"api/trainings/" + training_id + "/download?traineeId=" + data.trainee,
      true
    );
    request.responseType = "blob";
    request.setRequestHeader("Authorization", "Bearer " + store.get("accessToken"));
    // request.setRequestHeader("fngprt", m.cookie.get("fngprt"));
    request.onload = function(e) {
      // $(e.target).removeClass("loading");
      if (this.status === 200) {
        var file = window.URL.createObjectURL(this.response);
        // dispatch({
        //   type: "result/updateState",
        //   payload: { file: file },
        // });
        var a = document.createElement("a");
        a.href = file;
        a.download = this.response.name || (user.userName || user.email) + ".pdf";
        document.body.appendChild(a);
        a.click();
        window.onfocus = function() {
          // document.body.removeChild(a);
        };
      }
    };

    request.send();
  };

  render() {
    var { loading, data, assessment, organisation } = this.state;
    console.log(data,"datattt")
    var colSize = 12;
    var showScore =
      data.campaignId && data.campaignId.trainingId ? data.campaignId.trainingId.showScore || false : false;
    var showResult =
      data.campaignId && data.campaignId.trainingId ? data.campaignId.trainingId.showResult || false : false;
    var canDownloadCertificate = organisation && organisation.downloadCertificate;
    // var canDownloadCertificate = false;
    if (!showScore) {
      colSize = 24;
    }
    let assessmentObj = [];

    if(data.campaignId && data.campaignId.trainingId && data.campaignId.trainingId.isPreAssesmnet){
      for(let i = 0; i < assessment.length; i++){
        let flag = true,newObj = {},passed =0,fail=0;
        for(let j = assessment[i].startIndex; j <= assessment[i].endIndex; j++){
          if(flag)
            newObj.name = assessment[i].trainingId.name
          flag = false;
          if(data.intersactions[j].result == "correct") passed++
          else fail++
        }
        newObj.passed = passed;
        newObj.failed = fail;
        newObj.percentage = (passed / (passed+fail)) * 100;
        assessmentObj.push(newObj);
      }
    }
    console.log(assessmentObj,"assessmentObj",data.campaignId)

    return (
      <div>
        <TopNavbar key="topNavbarKey" />
        {loading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <div>
            <div>
              <div className="uk-padding resultBackground" style={{marginTop:"100px",paddingBottom:"70px"}}>
                <Row>
                  <Col lg={8} md={8} sm={24} xs={24}>
                    <img
                      src={require("assets/images/result_preview_icon.png")}
                      style={{ width: "120px", padding: "10px", margin:"20px" }}
                    />
                  </Col>

                  <Col lg={16} md={16} sm={24} xs={24}>
                    <h3 className="uk-margin" style={{color:"#ffffff"}}>
                      {data.campaignId.trainingId.name}
                    </h3>
                    <h5
                      className="uk-margin-remove"
                      style={{
                        letterSpacing: "1px",
                        fontSize: "1.2rem",
                        color:"#ffffff"
                      }}
                    >
                      Assigned On: {this.formateDateTime(data.assignedAt)}
                    </h5>
                    <h5
                      className="uk-margin-small-top"
                      style={{
                        letterSpacing: "1px",
                        fontSize: "1.2rem",
                        color:"#ffffff"
                      }}
                    >
                      Completed On: {data.submittedAt ? this.formateDateTime(data.submittedAt) : "Not completed yet"}
                    </h5>

                    {/* {data.campaignId.organisationId != "657984033cded1711b4df7ad" && data.campaignId.organisationId != "5e93dd248a5a1f35f4976e88" && data.campaignId.organisationId != "63c91ba4b8354485adce4306" && data.campaignId.organisationId != "644bb77d95143a668ab5e1c8" && data.campaignId.organisationId != "652e86da5df413ad507551e1" && data.campaignId.organisationId != "64f58c33e2ae74efdaa2d589" && data.campaignId.organisationId != "601843171b43137ae3f5426a" && data.campaignId.organisationId != "619e396026c80a4ceb4597dc" && data.trainingId != "6422ba796282779a45af4945" && ( */}
                    {false && (
                    <a href={`/trainee/leader-board/${this.state.trainingId}`} style={{padding:"12px",backgroundColor:"#8fddf3",color:"#000000",textDecoration:"none",fontWeight:"bold",borderRadius:"3px"}}>
                      <span><img
                            src={require("assets/images/leaderboard_icon.png")}
                            style={{ width: "20px"}}
                          />
                      </span>
                      <span style={{verticalAlign:"text-top",marginLeft:"10px"}}>LEADERBOARD</span>
                    </a>
                    )}
              
                  </Col>

                </Row>

              </div>

 
              <div
                style={{
                  paddingTop: "27%",paddingLeft:"10px",paddingRight:"10px",backgroundColor:"rgb(249 245 245)"
                }}
              >
                <div style={{ marginTop: "-30%" }}>
                  {!data.campaignId.trainingId.isPreAssesmnet && (
                    <Row>
                      <Col
                        lg={!showResult ? 11 : 7}
                        md={!showResult ? 11 : 7}
                        sm={24}
                        xs={24}
                        style={!showResult ? {textAlign:"center",backgroundColor:"#ffffff",marginRight:"10px",marginLeft:"44px",padding:"20px 0",borderRadius:"4px"} : {textAlign:"center",backgroundColor:"#ffffff",marginRight:"10px",marginLeft:"64px",padding:"20px 0",borderRadius:"4px"}}
                      >
                        <Progress
                          style={{ fontSize: "20px"}}
                          type="circle"
                          status={data.attempted ? "" : "exception"}
                          percent={data.attempted ? 100 : 0}
                          strokeWidth={17}
                          width={150}
                          strokeColor="#5bd775"
                        />
                        <div style={{ paddingTop: 20, fontSize: 24 }}>Assessment Completed</div>
                      </Col>
                      {showResult && (
                        <Col lg={7} md={7} sm={24} xs={24} style={{ textAlign: "center",backgroundColor:"#ffffff",marginRight:"10px",padding:"20px 0",borderRadius:"4px"}}>
                              <>
                                <Progress
                                  style={{ fontSize: "20px" }}
                                  type="circle"
                                  status={data.successStatus == "passed" ? "" : "exception"}
                                  percent={this.calculatePercent(data.scoreMax, data.scoreRaw)}
                                  strokeWidth={17}
                                  width={150}
                                  strokeColor="#5f76e8"
                                />
                                <div style={{ paddingTop: 20, fontSize: 24 }}>Result</div>
                              </>
                        </Col>
                      )}

                      <Col lg={!showResult ? 11 : 7} md={!showResult ? 11 : 7} sm={24} xs={24} style={{ textAlign: "center" ,backgroundColor:"#ffffff",padding:"20px 0",borderRadius:"4px"}}>
                        <div>
                          <img
                            src={require("assets/images/score_icon.png")}
                            style={{ width: "150px",  padding: "10px" }}
                          />
                        </div>
                        <div style={{ paddingTop: 20, fontSize: 24 }}>
                          Score :<strong style={{ fontSize: "2rem" }}> {data.trainingId != "6422ba796282779a45af4945" ? data.scoreRaw : ((data.scoreRaw / 100) * 110).toFixed(0) }</strong>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {data.campaignId.trainingId.isPreAssesmnet && assessmentObj.length > 0 && (
                    <div style={{ padding: "25px" }}>
                        <table className="table table-striped" style={{width:"90%",margin:"auto"}}>
                          <thead style={{backgroundColor:"black",color:"white"}}>
                            <tr>
                              <th style={{padding:"1%"}}>Assessment Name</th>
                              <th>Correct Answer</th>
                              <th>Wrong Answer</th>
                              <th>Percentage</th>
                            </tr>
                          </thead>
                          <tbody> 
                            {assessmentObj.map((assessment) => {
                              return(
                                <tr style={{borderBottom:"1px solid #e8e6e6"}}>
                                  <td style={{padding:"1%"}}>{assessment.name}</td>
                                  <td>{assessment.passed}</td>
                                  <td>{assessment.failed}</td>
                                  <td>{assessment.percentage} %</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                    </div>
                  )}

                  {!data.campaignId.trainingId.isPreAssesmnet && (
                    <div style={{ textAlign: "center", padding: "25px" }}>
                    <ul id="stats" style={{display:"inline-flex"}}>
                      {data.successStatus == "passed" && canDownloadCertificate && (
                        <li>
                          <img
                            onClick={this.downloadCertificate}
                            style={{
                              height: 40,
                              width:40,
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            src={require("assets/images/icon/download_icon.png")}
                          />
                        </li>
                      )}

                      {data.successStatus == "passed" && data.campaignId.organisationId != "64f58c33e2ae74efdaa2d589" && (
                        <li>
                          <img
                            data="facebook"
                            onClick={()=>this.shareCertificate("facebook")}
                            style={{
                              height: 40,
                              width:40,
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            src={require("assets/images/icon/facebook_icon.png")}
                          />
                        </li>
                      )}
                      {data.successStatus == "passed" &&  data.campaignId.organisationId != "64f58c33e2ae74efdaa2d589" &&(
                        <li>
                          <img
                            data="linkedIn"
                            onClick={()=>this.shareCertificate("linkedIn")}
                            style={{ height: 40, 
                              width:40,cursor: "pointer" ,
                              marginRight: 10,}}
                            src={require("assets/images/icon/linkedin_icon.png")}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ResultView;
